import(/* webpackMode: "eager" */ "/codebuild/output/src2066880180/src/Vanquish-UI/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["AmplifyProvider"] */ "/codebuild/output/src2066880180/src/Vanquish-UI/components/Hooks/AmplifyProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["EverflowProvider"] */ "/codebuild/output/src2066880180/src/Vanquish-UI/components/Hooks/EverflowProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["RootLayoutProvider"] */ "/codebuild/output/src2066880180/src/Vanquish-UI/components/Hooks/RootLayoutProvider.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2066880180/src/Vanquish-UI/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2066880180/src/Vanquish-UI/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2066880180/src/Vanquish-UI/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2066880180/src/Vanquish-UI/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2066880180/src/Vanquish-UI/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-inter\"}],\"variableName\":\"inter\"}");
